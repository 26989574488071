import React, { useEffect, useState } from 'react';
import Product from "./Product";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Carousel, Col, Container, Modal, Row } from "react-bootstrap";

export default function Products(props) {
    const [variantId, setVariantId] = useState(undefined);
    const [focusedProduct, setFocusedProduct] = useState(undefined);
    const [products, setProducts] = useState([]);
    const [index, setIndex] = useState(0);
    const [atLeastOneAvailable, setAtLeastOneAvailable] = useState(false);

    const history = useHistory();
    const [hasScrolled, setHasScrolled] = useState(false);
    const anchorUrl = typeof window !== "undefined" && window.location.href.split("#")[1];
    let stopScrolling = false;

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        props.client && props.client.collection.fetchWithProducts("Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzI3NzM0MzE0MjA2MQ==").then((res) => {
            setProducts(res.products);
        });
    }, [props.client]);

    useEffect(() => {
        return history.listen((location) => {
            scrollToUrl(location.hash && location.hash.slice(1));
        });
    }, [history]);

    useEffect(() => {
        scrollToUrl();
    }, [anchorUrl, products]);

    useEffect(() => {
        window.addEventListener('DOMMouseScroll wheel', (event) => {
            setHasScrolled(true);
        });

        return () => {
            window.removeEventListener('DOMMouseScroll wheel', (event) => {
                setHasScrolled(false);
            });
        }
    }, []);

    const scrollToUrl = (hash) => {
        if (!stopScrolling) {
            const header = document.getElementById("header");
            let anchor;
            if (hash !== "") {
                anchor = hash || anchorUrl;
            }
            let toTop = 0;
            if (anchor && header)
                toTop = document.getElementById(anchor).offsetTop - header.offsetHeight - 20;
            window.scrollTo(0, toTop);
        }
    };

    const addToCart = () => {
        const lineItemsToAdd = [{ variantId: variantId, quantity: 1 }];

        return props.client.checkout.addLineItems(props.checkout.id, lineItemsToAdd).then(res => {
            props.setCheckout(res);
            setFocusedProduct(undefined);
            setVariantId(undefined);
            setAtLeastOneAvailable(false);
            toast.success(" ✓ L'article a été ajouté à votre panier !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    const hideModal = () => {
        setFocusedProduct(undefined);
        setVariantId(undefined);
        setAtLeastOneAvailable(false);
    };

    const renderPopin = () => {
        return (
            <Modal
                show={!!focusedProduct}
                size="xl"
                fullscreen={"sm-down"}
                scrollable={true}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName={"width-80 width-full-mobile zindex-1000"}
                centered
                onHide={hideModal}
            >
                <Col sm={{ span: 1, offset: 11 }} xs={{ span: 1, offset: 11 }}>
                    <Button className={"close"} onClick={hideModal}>X</Button>
                </Col>
                <Modal.Body>
                    <Row>
                        <Col sm={{ span: 7 }} xs={{ span: 12 }}>
                            {focusedProduct && focusedProduct.images && focusedProduct.images.length ?
                                <Carousel activeIndex={index} onSelect={handleSelect} nextLabel={""} prevLabel={""}>
                                    {focusedProduct.images.map((img, index) =>
                                        <Carousel.Item key={`${index}-carousel-img`}>
                                            <img
                                                className="d-block w-100"
                                                src={img.src}
                                                alt={`${focusedProduct.title}-${index}`}
                                            />
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                                : null
                            }
                        </Col>
                        <Col sm={{ span: 5 }} xs={{ span: 12 }}>
                            <Container className={"width-80 width-full-mobile"}>
                                <Row className={"top-60 top-30-mobile"}>
                                    <Col sm={{ span: 4, offset: 0 }} xs={{ span: 4, offset: 0 }}>
                                        <span className={"cursor-pointer grey"}
                                            onClick={hideModal}> {"< RETOUR"} </span>
                                    </Col>
                                    <Col sm={{ span: 5, offset: 3 }} xs={{ span: 6, offset: 2 }}
                                        className={"text-right"}>
                                        <Link to={"/cart"}
                                            className={"a-reset grey"}> {`PANIER (${props.checkout.lineItems.length ? props.checkout.lineItems.reduce((total, item) => {
                                                total += item.quantity;
                                                return total;
                                            }, 0) : 0})`} </Link>
                                    </Col>
                                </Row>
                                <Row className={"top-30 font-size-125"}>
                                    <Col>
                                        {focusedProduct && focusedProduct.title}
                                    </Col>
                                </Row>
                                <Row className={"font-size-125 weight-light-italic"}>
                                    <Col>
                                        {focusedProduct && focusedProduct.variants && `${focusedProduct.variants[0].price} €`}
                                    </Col>
                                </Row>
                                <Row className={"top-15 width-80 margin-auto"} sm={4} xs={4}>
                                    {focusedProduct && focusedProduct.variants && focusedProduct.variants.map((variant, index) => {
                                        if (!atLeastOneAvailable && variant.available) {
                                            setAtLeastOneAvailable(true);
                                            if (focusedProduct.variants.length === 1) {
                                                setVariantId(variant.id)
                                            }
                                        }
                                        let size = <span>{variant.title}</span>;
                                        return <Button disabled={!variant.available}
                                            className={`text-center size-chooser button-size ${index ? "left-auto" : ""}`}
                                            key={`${index}-size-buttons`}
                                            onClick={() => setVariantId(variant.id)}
                                            variant={variant.id === variantId ? "dark" : "outline-dark"}>
                                            {size}
                                        </Button>
                                    })}
                                </Row>
                                <Row className={"width-80 margin-auto"}>
                                    <Button className={"buy-button button-size"}
                                        disabled={!atLeastOneAvailable || !variantId}
                                        onClick={addToCart} variant={"dark"}>
                                        AJOUTER AU PANIER
                                    </Button>
                                </Row>
                                <Row className={"top-30 weight-light"} dangerouslySetInnerHTML={{ __html: focusedProduct ? focusedProduct.descriptionHtml : "" }}>
                                </Row>
                                <br />
                                <br />
                            </Container>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        )
    };


    return (
        <Container id={"products"}>
            <Row xl={4} lg={4} md={4} xs={2}>
                {products.map((product) =>
                    <Product
                        addVariantToCart={props.addVariantToCart}
                        client={props.client}
                        key={product.id.toString()}
                        product={product}
                        setFocusedProduct={setFocusedProduct}
                        scrollToUrl={() => !hasScrolled && scrollToUrl()}
                        hasScrolled={hasScrolled}
                        setHasScrolled={setHasScrolled}
                    />
                )}
            </Row>
            {renderPopin()}
        </Container>
    );
}
